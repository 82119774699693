import styled from "styled-components";
import { NavLink as RRDLink } from "react-router-dom";
import {
  compose,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

interface StyleProps extends TypographyProps, FlexboxProps, SpaceProps {}

const styleProps = compose(typography, flexbox, space);

export const Link = styled(RRDLink)<StyleProps>`
  display: flex;
  justify-content: center;
  text-decoration: none;
  font: inherit;
  color: inherit;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-input-border);
  border-radius: ${({ theme }) => theme.space[0]};
  background-color: transparent;
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
  text-align: center;
  ${styleProps};

  :hover {
    background-color: ${({ theme }) => theme.colors.bg[1]};
  }

  .active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;
