import { Outlet, useLoaderData, useRouteError } from "react-router-dom";
import {
  IconPaperPlane,
  IconPhone,
  IconSkullCrossbones,
} from "~/src/assets/icons";
import { InfoWell } from "~/src/components/info-well";
import { UIText } from "~/src/components/ui-text";
import { commands } from "~/src/api/commands";
import { Button } from "~/src/components/button";
import { Box, Column, Row } from "~/src/components/layout";
import { Link } from "~/src/components/link";
import { UserFragment } from "~/src/components/user";
import QsicCombomarkUrl from "~/src/assets/svgs/qsic-combomark-dark.svg";
import { Icon } from "~/src/components/icon";

export const rootLoader = async (): Promise<{
  success: boolean;
  user?: Awaited<ReturnType<(typeof commands)["getUser"]>>;
}> => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return { success: false };
    const user = await commands.getUser({ accessToken });
    return { success: true, user };
  } catch (err) {
    console.error(err);
    return { success: false };
  }
};

export const RootView = () => {
  const loader = useLoaderData() as Awaited<ReturnType<typeof rootLoader>>;

  return (
    <Column gap="2">
      <UserFragment
        user={{
          username: loader.user?.Username,
          email: loader.user?.UserAttributes?.find((a) => a.Name === "email")
            ?.Value,
        }}
      />

      {!loader.success && (
        <Button as={Link} to="login">
          Login
        </Button>
      )}
      {loader.success && (
        <>
          <Button as={Link} to="change-password">
            Change Password
          </Button>
          <Button as={Link} to="logout">
            Logout
          </Button>
        </>
      )}
    </Column>
  );
};

export const RootLayout = () => {
  return (
    <Column flex="1">
      <Row p="3" justifyContent="center">
        <Box mr="2" as="img" src={QsicCombomarkUrl} />
      </Row>
      <Column
        width="100%"
        maxWidth="320px"
        alignSelf="center"
        justifySelf="center"
        flex="1"
      >
        <Outlet />
        <Column mt="auto" p="2" gap="2">
          <UIText as="p" textAlign="center">
            Need help?
          </UIText>
          <Link to="tel:1300 113 279">
            <Row gap="1">
              <Icon glyph={<IconPhone />} /> 1300 113 279
            </Row>
          </Link>
          <Link to="mailto:support@getqsic.com">
            <Row gap="1">
              <Icon glyph={<IconPaperPlane />} />
              support@getqsic.com
            </Row>
          </Link>
        </Column>
      </Column>
    </Column>
  );
};

export const RootFallbackErrorView = () => {
  useRouteError();
  return (
    <Column flex="1" p="2">
      <InfoWell
        icon={<IconSkullCrossbones />}
        iconColor="error"
        title="Something went wrong"
      />
    </Column>
  );
};
