import styled from "styled-components";
import { UIText } from "./ui-text";
import { Row } from "./layout";
import { IconLoading } from "./icon-loading";

export const Button = styled.button``;

export const SubmitButton = (props: {
  isSubmitting: boolean;
  submittingText: string;
  idleText: string;
}) => (
  <Button type="submit" disabled={props.isSubmitting}>
    {props.isSubmitting ? (
      <UIText mr="2">
        <Row alignItems="center" gap="1" justifyContent="center">
          <IconLoading /> {props.submittingText}
        </Row>
      </UIText>
    ) : (
      <UIText>{props.idleText}</UIText>
    )}
  </Button>
);
