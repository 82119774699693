import { IconLoading } from "~/src/components/icon-loading";
import { Column } from "~/src/components/layout";

export const LoadingView = () => {
  return (
    <Column minHeight="4" alignItems="center">
      <IconLoading />
    </Column>
  );
};
