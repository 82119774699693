import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import {
  loginChallengeAction,
  loginChallengeLoader,
  LoginChallengeView,
} from "~/src/views/login/challenge";
import { LoginErrorView, loginAction, LoginView } from "~/src/views/login";
import { ForgotPasswordView } from "./views/forgot-password";
import { logoutAction, logoutLoader, LogoutView } from "./views/logout";
import { ChangePasswordView } from "~/src/views/change-password";
import { NotFoundView } from "./views/fragments/not-found";
import { AuthorizeView } from "./views/oauth/authorize";
import {
  RootFallbackErrorView,
  RootLayout,
  rootLoader,
  RootView,
} from "~/src/views/";
import { refreshLoader, RefreshView } from "~/src/views/login/refresh";
import { LogoutSuccessView } from "~/src/views/logout/success";
import { wrapCreateBrowserRouter } from "@sentry/react";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<RootLayout />}
      errorElement={<RootFallbackErrorView />}
    >
      <Route index={true} loader={rootLoader} element={<RootView />} />
      <Route path="login">
        <Route
          index={true}
          element={<LoginView />}
          errorElement={<LoginErrorView />}
          action={loginAction}
        />
        <Route
          path="challenge"
          loader={loginChallengeLoader}
          element={<LoginChallengeView />}
          action={loginChallengeAction}
        />
        <Route
          path="refresh"
          loader={refreshLoader}
          element={<RefreshView />}
        />
      </Route>
      <Route path="forgot-password" element={<ForgotPasswordView />} />
      <Route path="change-password" element={<ChangePasswordView />} />
      <Route path="oauth">
        <Route index={true} element={<NotFoundView />} />
        <Route path="authorize" element={<AuthorizeView />} />
      </Route>
      <Route path="logout">
        <Route
          index={true}
          loader={logoutLoader}
          element={<LogoutView />}
          action={logoutAction}
        />
        <Route path="success" element={<LogoutSuccessView />} />
      </Route>
      <Route path="/*" element={<NotFoundView />} />
    </Route>
  )
);
