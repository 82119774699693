import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Navigate,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { commands } from "~/src/api/commands";
import { IconSkullCrossbones } from "~/src/assets/icons";
import { SubmitButton } from "~/src/components/button";
import { Form } from "~/src/components/form";
import { InfoWell } from "~/src/components/info-well";
import { Column } from "~/src/components/layout";
import {
  ERROR_CONFIRM_PASSWORD,
  ERROR_PASSWORD_LENGTH,
  ERROR_PASSWORD_MISMATCH,
} from "~/src/strings";

export const loginChallengeLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  return {
    session: url.searchParams.get("session"),
    username: url.searchParams.get("username"),
  };
};

export const loginChallengeAction = async ({
  request,
}: ActionFunctionArgs): Promise<{ success: boolean; error?: Error } | void> => {
  try {
    const formData = await request.formData();
    const actionUrl = new URL(request.url);

    const proposedPassword = formData.get("new-password")?.toString();
    const confirmPassword = formData.get("confirm-new-password")?.toString();
    if (!proposedPassword || !confirmPassword)
      throw new Error(ERROR_CONFIRM_PASSWORD);
    if (proposedPassword !== confirmPassword)
      throw new Error(ERROR_PASSWORD_MISMATCH);
    if (proposedPassword.length < 8) throw new Error(ERROR_PASSWORD_LENGTH);

    const session = actionUrl.searchParams.get("session")!;
    const username = actionUrl.searchParams.get("username")!;
    const result = await commands.challengeChangePassword({
      proposedPassword,
      session,
      username,
    });
    if (
      result.AuthenticationResult &&
      result.AuthenticationResult.AccessToken &&
      result.AuthenticationResult.RefreshToken
    ) {
      localStorage.setItem(
        "accessToken",
        result.AuthenticationResult?.AccessToken
      );
      localStorage.setItem(
        "refreshToken",
        result.AuthenticationResult?.RefreshToken
      );
      return { success: true };
    }
    return {
      success: false,
      error: new Error("No credentials were returned."),
    };
  } catch (err) {
    return { success: false, error: err as Error };
  }
};

export const LoginChallengeView = () => {
  const loader = useLoaderData() as Awaited<
    ReturnType<typeof loginChallengeLoader>
  >;
  const action = useActionData() as Awaited<
    ReturnType<typeof loginChallengeAction>
  >;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  if (action?.success) return <Navigate to="/" />;

  return (
    <Column gap="2">
      {action?.error && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[
            "Something went wrong setting your new password.",
            action.error.message,
          ]}
        />
      )}
      <Form
        method="post"
        action={`?session=${loader?.session}&username=${loader?.username}`}
      >
        <Column gap="1">
          <label htmlFor="new-password">New password</label>
          <input
            id="new-password"
            name="new-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="confirm-new-password">Confirm password</label>
          <input
            id="confirm-new-password"
            name="confirm-new-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <SubmitButton
          isSubmitting={isSubmitting}
          submittingText="Setting new password..."
          idleText="Set Password"
        />
      </Form>
    </Column>
  );
};
