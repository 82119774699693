import {
  ActionFunctionArgs,
  Navigate,
  useActionData,
  useNavigation,
  useRouteError,
} from "react-router-dom";
import { Column, Row } from "~/src/components/layout";
import { InfoWell } from "~/src/components/info-well";
import { IconSkullCrossbones } from "~/src/assets/icons";
import { commands } from "~/src/api/commands";
import { Link } from "~/src/components/link";
import { Form } from "~/src/components/form";
import { ERROR_MISSING_USERNAME_PASSWORD } from "~/src/strings";
import { LoadingView } from "../fragments/loading";
import { IconLoading } from "~/src/components/icon-loading";
import { UIText } from "~/src/components/ui-text";
import { SubmitButton } from "~/src/components/button";

export const loginAction = async ({
  params,
  request,
  context,
}: ActionFunctionArgs): Promise<{
  success: boolean;
  challenge?: { session: string; username: string };
  error?: Error;
} | void> => {
  try {
    const formData = await request.formData();
    const username = formData.get("username")?.toString();
    const password = formData.get("password")?.toString();

    if (!(username && password))
      throw new Error(ERROR_MISSING_USERNAME_PASSWORD);

    const result = await commands.loginUsernamePassword({
      username,
      password,
    });

    if (result.AuthenticationResult) {
      localStorage.setItem(
        "accessToken",
        result.AuthenticationResult.AccessToken!
      );
      localStorage.setItem(
        "refreshToken",
        result.AuthenticationResult.RefreshToken!
      );
      return { success: true };
    }

    if (result.ChallengeName)
      return {
        success: true,
        challenge: { session: result.Session!, username },
      };

    return { success: false };
  } catch (err) {
    console.error(err);
    return { success: false, error: err as Error };
  }
};

export const LoginView = () => {
  const action = useActionData() as Awaited<ReturnType<typeof loginAction>>;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isLoading = navigation.state === "loading";

  if (isLoading) return <LoadingView />;
  if (action?.success && action?.challenge)
    return (
      <Navigate
        to={`challenge/?session=${action.challenge.session}&username=${action.challenge.username}`}
      />
    );
  if (action?.success) return <Navigate to="/" />;

  return (
    <Column gap="2">
      {action?.error && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[
            "Something went wrong with your login.",
            action.error.message,
          ]}
        />
      )}
      <Form aria-label="Login form" method="post" action="?index">
        <Column gap="1">
          <label id="username-field-label" htmlFor="username">
            Username or email address
          </label>
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            aria-labelledby="username-field-label"
          />
        </Column>
        <Column gap="1">
          <label id="password-field-label" htmlFor="password">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            aria-labelledby="password-field-label"
          />
        </Column>
        <SubmitButton
          isSubmitting={isSubmitting}
          submittingText="Logging in..."
          idleText="Login"
        />
        <Link to="../forgot-password">Forgot Password</Link>
      </Form>
    </Column>
  );
};

export const LoginErrorView = () => {
  const error = useRouteError() as Error;
  return (
    <Column gap="3">
      <InfoWell
        icon={<IconSkullCrossbones />}
        title="Error"
        description={["You are not able to login right now.", error.message]}
      />
    </Column>
  );
};
