import {
  ActionFunctionArgs,
  Navigate,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import invariant from "tiny-invariant";
import { commands } from "~/src/api/commands";
import {
  IconExclamationTriangle,
  IconSkullCrossbones,
} from "~/src/assets/icons";
import { SubmitButton } from "~/src/components/button";
import { Form } from "~/src/components/form";
import { InfoWell } from "~/src/components/info-well";
import { Column } from "~/src/components/layout";

export const logoutLoader = async (): Promise<{ success: boolean }> => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (accessToken || refreshToken) {
    return { success: false };
  }
  return { success: true };
};

export const logoutAction = async ({
  params,
  request,
  context,
}: ActionFunctionArgs): Promise<{ success: boolean; error?: Error }> => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    invariant(
      accessToken,
      "No access token available: you're already signed out."
    );
    await commands.signOut({ accessToken });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    return { success: true };
  } catch (err) {
    console.error(err);
    return { success: false, error: err as Error };
  }
};

export const LogoutView = () => {
  const loader = useLoaderData() as Awaited<ReturnType<typeof logoutLoader>>;
  const action = useActionData() as Awaited<ReturnType<typeof logoutAction>>;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  if (loader?.success || action?.success) return <Navigate to="success" />;
  return (
    <Column gap="2">
      <>
        {action?.error && (
          <InfoWell
            icon={<IconSkullCrossbones />}
            iconColor="error"
            title="Error"
            description={[
              "There a problem logging you out. Please try again or try refreshing the page.",
            ]}
          />
        )}
        <InfoWell
          icon={<IconExclamationTriangle />}
          iconColor="warning"
          title="Warning"
          description={[
            "Logging out here will deauthorize any connected apps using your Qsic account",
          ]}
        />
        <Form method="post" action="?index">
          <SubmitButton
            isSubmitting={isSubmitting}
            submittingText="Logging out..."
            idleText="Logout Everywhere"
          />
        </Form>
      </>
    </Column>
  );
};
