import { CognitoIdentityProviderClient as CognitoClient } from "@aws-sdk/client-cognito-identity-provider";
import { AWS_COGNITO_ENDPOINT, AWS_REGION } from "../strings";

export const client = new CognitoClient({
  region: AWS_REGION,
  endpoint: `https://${AWS_COGNITO_ENDPOINT}`,
  credentials: {
    accessKeyId: "",
    secretAccessKey: "",
  },
});
