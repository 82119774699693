import { Box, Column, Row } from "./layout";
import { UIText } from "./ui-text";
import md5 from "md5";
import { Icon } from "./icon";
import { IconCopy, IconUserSecret } from "../assets/icons";
import { Button } from "./button";

export const createGravatarUrl = (
  emailAddress?: string
): string | undefined => {
  if (emailAddress && emailAddress.length > 0) {
    const id = md5(emailAddress.trim().toLocaleLowerCase());
    return `https://gravatar.com/avatar/${id}?d=mp`;
  }
};

export interface User {
  username?: string;
  email?: string;
}

export const UserFragment = (props: { user: User }) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  return (
    <Column
      borderWidth="1px"
      borderStyle="solid"
      borderColor="bg.0"
      borderRadius="1"
      p="2"
    >
      <Row gap="2">
        <Column flex="0 0 auto">
          {props.user.email ? (
            <Box
              as="img"
              width="1"
              height="1"
              borderRadius="0"
              src={createGravatarUrl(props.user.email)}
            />
          ) : (
            <Icon
              width="1"
              height="1"
              fontSize="4"
              bg="bg.0"
              glyph={<IconUserSecret />}
            />
          )}
        </Column>

        <Column as="dl" gap="1" flex="1">
          <Column>
            <UIText as="dt">Username</UIText>
            <UIText as="dd" truncate={true}>
              {props.user.username ?? "None"}
            </UIText>
          </Column>
          <Column>
            <UIText as="dt">Email</UIText>
            <UIText as="dd" truncate={true}>
              {props.user.email ?? "None"}
            </UIText>
          </Column>
          <Row alignItems="center" gap="1">
            <Column flex="1">
              <UIText as="dt">Access Token</UIText>
              <UIText as="dd" truncate={true}>
                {accessToken ?? "None"}
              </UIText>
            </Column>
            {accessToken && (
              <Button
                onClick={() => navigator.clipboard.writeText(accessToken)}
              >
                <Icon glyph={<IconCopy />} />
              </Button>
            )}
          </Row>
          <Row alignItems="center" gap="1">
            <Column flex="1">
              <UIText as="dt">Refresh Token</UIText>
              <UIText as="dd" truncate={true}>
                {refreshToken ?? "None"}
              </UIText>
            </Column>
            {refreshToken && (
              <Button
                onClick={() => navigator.clipboard.writeText(refreshToken)}
              >
                <Icon glyph={<IconCopy />} />
              </Button>
            )}
          </Row>
        </Column>
      </Row>
    </Column>
  );
};
