import { createRoot } from "react-dom/client";
import invariant from "tiny-invariant";
import { router } from "./router";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./theme";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import "./sentry";

export const Routes = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

const container = document.getElementById("app");
invariant(container, "`#app not found");
const root = createRoot(container);

root.render(<Routes />);
