import { Navigate, useLoaderData } from "react-router-dom";
import invariant from "tiny-invariant";
import { commands } from "~/src/api/commands";
import { IconLoading } from "~/src/components/icon-loading";
import { ERROR_REFRESH_FAILED } from "~/src/strings";

export const refreshLoader = async (): Promise<{ success: boolean } | void> => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    invariant(refreshToken, "No refresh token available.");
    const result = await commands.loginRefresh({
      refreshToken,
    });
    if (result.AuthenticationResult) {
      localStorage.setItem(
        "accessToken",
        result.AuthenticationResult.AccessToken!
      );
      localStorage.setItem(
        "refreshToken",
        result.AuthenticationResult.RefreshToken!
      );
      return { success: true };
    }
    throw new Error(ERROR_REFRESH_FAILED);
  } catch (err) {
    console.error(err);
    return { success: false };
  }
};

export const RefreshView = () => {
  const loader = useLoaderData() as Awaited<ReturnType<typeof refreshLoader>>;

  if (loader?.success) return <Navigate to="/" />;
  if (loader?.success === false) return <Navigate to="/login" />;
  return <IconLoading />;
};
