export const AWS_REGION = process.env.AWS_REGION ?? "ap-southeast-2";
export const AWS_APP_CLIENT_ID = process.env.AWS_APP_CLIENT_ID;
export const AWS_COGNITO_ENDPOINT = process.env.AWS_COGNITO_ENDPOINT;
export const AWS_USER_POOL_ID = process.env.AWS_USER_POOL_ID;

export const ERROR_MISSING_CLIENT_ID = "Must provide a client ID.";
export const ERROR_CONFIRM_PASSWORD = "Please confirm your new password.";
export const ERROR_PASSWORD_MISMATCH = "Your passwords must match.";
export const ERROR_PASSWORD_LENGTH =
  "Your new password must be at least 8 characters long.";
export const ERROR_MISSING_USERNAME_PASSWORD =
  "Please provide a username and password.";
export const ERROR_REFRESH_FAILED = "Failed to refresh your session.";
export const ERROR_FETCH_ENV_FAILED = "Failed to retrieve app configuration.";
