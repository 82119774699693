import styled from "styled-components";
import {
  borders,
  BordersProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  RequiredTheme,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  Theme,
  ThemeValue,
  typography,
  TypographyProps,
} from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";

interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<"space", ThemeType>
> {
  /** Margin on top, left, bottom and right */
  gap?: ResponsiveValue<TVal, ThemeType>;
}

const gap = system({
  gap: {
    property: "gap",
    scale: "space",
  },
});

interface StyleProps
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    GapProps,
    FlexboxProps,
    BordersProps,
    TypographyProps,
    PositionProps {}

const styleProps = compose(
  color,
  space,
  layout,
  gap,
  flexbox,
  borders,
  typography,
  position
);

export const Box = styled.div.withConfig<StyleProps>({ shouldForwardProp })`
  ${styleProps};
`;

export const Column = styled.div.withConfig<StyleProps>({ shouldForwardProp })`
  display: flex;
  flex-direction: column;
  ${styleProps};
`;

export const Row = styled.div.withConfig<StyleProps>({ shouldForwardProp })`
  display: flex;
  ${styleProps};
`;

export const Hairline = styled.hr<StyleProps>`
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--color-input-border);
  margin: 0;
  ${styleProps};
`;

export const HairlineInline = styled.hr<StyleProps>`
  width: 1px;
  border: none;
  background-color: var(--color-input-border);
  align-self: stretch;
  margin: 0;
`;
