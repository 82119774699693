import styled from "styled-components";
import {
  color,
  ColorProps,
  compose,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  FlexProps,
  BorderRadiusProps,
  borderRadius,
  OrderProps,
  order,
  FlexboxProps,
  flexbox,
  GridProps,
  grid,
} from "styled-system";

const styleProps = compose(
  color,
  order,
  space,
  typography,
  layout,
  borderRadius,
  flexbox,
  grid
);

export interface UITextCoreProps
  extends ColorProps,
    SpaceProps,
    TypographyProps,
    FlexProps,
    OrderProps,
    LayoutProps,
    BorderRadiusProps,
    FlexboxProps,
    GridProps {
  textTransform?: "capitalize" | "uppercase" | "lowercase";
}

export interface UITextProps extends UITextCoreProps {
  truncate?: boolean;
  tabular?: boolean;
}

export const UIText = styled.div<UITextProps>`
  white-space: ${({ truncate }) => (truncate ? "nowrap" : "unset")};
  overflow: ${({ truncate }) => (truncate ? "hidden" : "unset")};
  text-overflow: ellipsis;
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : undefined};
  font-variant-numeric: ${({ tabular }) =>
    tabular ? "tabular-nums" : "normal"};
  ${styleProps}
`;

export const UITextSpan = styled.span<UITextProps>`
  font-variant-numeric: ${({ tabular }) =>
    tabular ? "tabular-nums" : "normal"};
  ${styleProps}
`;
