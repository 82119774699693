import { IconCheckCircleSolid } from "~/src/assets/icons";
import { InfoWell } from "~/src/components/info-well";
import { Column } from "~/src/components/layout";
import { Link } from "~/src/components/link";

export const LogoutSuccessView = () => {
  return (
    <Column gap="2">
      <InfoWell
        icon={<IconCheckCircleSolid />}
        iconColor="success"
        title="You are logged out"
      />
      <Link to="/login">Login</Link>
    </Column>
  );
};
