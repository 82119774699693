import { IconExclamationTriangle } from "~/src/assets/icons";
import { InfoWell } from "~/src/components/info-well";

export const NotFoundView = () => (
  <InfoWell
    icon={<IconExclamationTriangle />}
    iconColor="warning"
    title="Not Found"
    description={[
      "This page does not exist, or you do not have access right now.",
    ]}
  />
);
