import styled, { DefaultTheme } from "styled-components";
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

interface StyleProps
  extends TypographyProps,
    ColorProps,
    SpaceProps,
    LayoutProps {}

const styleProps = compose(typography, color, space, layout);

const IconWrapper = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  ${styleProps};
`;

export const Icon = (
  props: { glyph: React.ReactNode } & Omit<StyleProps, "color"> & {
      color?: keyof DefaultTheme["colors"];
    }
) => {
  const { glyph, ...rest } = props;
  return <IconWrapper {...rest}>{glyph}</IconWrapper>;
};
