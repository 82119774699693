import { Column, Row } from "./layout";
import { UIText } from "./ui-text";

export const InfoWell = (props: {
  title: string;
  description?: string[];
  icon: React.ReactNode;
  iconColor?: string;
}) => {
  return (
    <Column p="2" gap="1" bg="bg.0" borderRadius="1">
      <Row gap="1" alignItems="center">
        <Column color={props.iconColor} fontSize="3">
          {props.icon}
        </Column>
        <UIText as="h1" fontSize="2">
          {props.title}
        </UIText>
      </Row>
      {props.description?.map((d, i) => (
        <UIText as="p" color="text.4" key={i} fontSize="1">
          {d}
        </UIText>
      ))}
    </Column>
  );
};
