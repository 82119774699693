import { Form as ReactRouterForm, FormProps } from "react-router-dom";
import { Column } from "./layout";

export const Form = (props: FormProps) => {
  const { children, ...rest } = props;
  return (
    <Column
      as={ReactRouterForm}
      {...rest}
      gap="2"
      p="2"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="bg.0"
      borderRadius="1"
    >
      {children}
    </Column>
  );
};
