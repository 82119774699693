import { IconExclamationTriangle } from "~/src/assets/icons";
import { InfoWell } from "~/src/components/info-well";

export const NotImplementedView = () => (
  <InfoWell
    icon={<IconExclamationTriangle />}
    iconColor="warning"
    title="Not Implemented"
    description={[
      "This feature isn't implemented yet, but its coming!",
      "Please check back soon.",
    ]}
  />
);
