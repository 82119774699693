import { createGlobalStyle } from "styled-components";
import "styled-components";

export type ThemeScaleWithAlias<T extends string> = string[] &
  Record<T, string>;

const greys = [
  "#FAFAFF",
  "#EDEFFC",
  "#DFE1F6",
  "#CFD2ED",
  "#BEC1E1",
  "#ACAED0",
  "#9798BB",
  "#7F7F9F",
  "#65647E",
  "#474659",
  "#383746",
  "#312F3C",
  "#292833",
];

export const theme = {
  space: ["0.25rem", "0.5rem", "1rem", "2rem"],
  radii: ["0.25rem", "0.5rem", "1rem", "2rem"],
  sizes: ["1rem", "2.5rem", "5rem", "8rem"],
  fontSizes: ["0.75rem", "0.875rem", "1rem", "1.5rem", "2rem"],
  colors: {
    text: greys,
    bg: [...greys].reverse(),
    success: "#4FE3C2",
    error: "#FF6055",
    warning: "#FFB555",
    accent: "#8080FF",
    canvas: "#1B1A25",
  },
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-accent: ${({ theme }) => theme.colors.accent};
    --color-bg-canvas: ${({ theme }) => theme.colors.canvas};
    --color-input-border: ${({ theme }) => theme.colors.bg[2]};
    --color-input-text-placeholder: ${({ theme }) => theme.colors.bg[5]};

    --color-bg-button-bg: var(--color-accent);
    --color-bg-button-disabled: var(--color-input-border);
    --bg-0: ${({ theme }) => theme.colors.bg[0]};
    --bg-1: ${({ theme }) => theme.colors.bg[1]};

    background-color: var(--color-bg-canvas);
  }

  .debug-ui * {
    outline: 1px dashed #ff00ff;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.accent};
  }

  input, button {
    font: inherit;
    color: inherit;
    appearance: none;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-input-border);
    border-radius: ${({ theme }) => theme.space[0]};
    background-color: transparent;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
  }

  input:focus, button:focus {
    outline: none;
  }

  input:focus-visible, button:focus-visible {
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: var(--color-accent);
  }

  input::placeholder {
    color: var(--color-input-text-placeholder);
  }

  button[type="submit"] {
    background-color: var(--color-bg-button-bg);
    /* color: #fff; */
    padding-block: 0.5rem;
    cursor: pointer;
  }

  button[type="submit"]:disabled {
    background-color: var(--color-bg-button-disabled);
  }

  label, dt {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    color: ${({ theme }) => theme.colors.text[4]};
    letter-spacing: 0.15em;
  }
`;

declare module "styled-components" {
  type Theme = typeof theme;
  export interface DefaultTheme extends Theme {}
}
